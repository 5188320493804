.table-of-contents
{
  position: sticky;
  top: 100px;

  &_title
  {
    color: #292929;
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
  }

  &_content
  {
    font-size: 16px;

    ul
    {
      counter-reset: List;
      margin-left: 5px;

      li
      {
        counter-increment: List;

        ul
        {
          margin-left: 25px;
          font-size: 14px;
          font-weight: 300;

          a
          {
            font-weight: 300;
            font-size: 14px;
          }
        }
      }

    }

    a
    {
      color: #2f4052;
      font-weight: 500;

      &::before
      {
        font-family: inherit;
        content: counters(List,".");
        margin-right: 10px;
        font-weight: bold;
        color: #2f4052;
      }

      &:hover
      {
        color: #FF5A23!important;

        &::before
        {
          color: #FF5A23!important;
        }
      }
    }
  }
}