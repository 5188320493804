.section-courses
{
  background: #f8f8f8;
  padding: 50px 0;

  &.black
  {
    background: #000;
  }

  h2
  {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 42px;
    text-align: center;
    margin-bottom: 4px;
  }

  &_header
  {
    margin-bottom: 50px;

    h3
    {
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      text-align: center;
      font-size: 20px;
      line-height: 1.6em;
    }
  }

  .card:hover
  {
    box-shadow: 0 5px 12px rgba(126, 142, 177, .2);
  }

  .card-body
  {
    padding: 1.5rem;

    & > :last-child
    {
      margin-bottom: 0;
    }

    .item-card7-text
    {
      margin-bottom: 12px !important;

      .text-dark
      {
        color: #2e384d !important;
      }

      h4
      {
        font-weight: 600;
        font-size: 20px;
      }
    }
  }

  .course
  {
    &-desc
    {
      font-weight: 300;
    }

    &-actions
    {
      margin-top: 20px;

      &_details
      {

      }

      &_buy
      {
        color: #1c1d1f;
        background-color: transparent;
        border: 1px solid #1c1d1f;

        &:hover
        {
          color: #fff;
          background-color: #1c1d1f;
        }
      }
    }
  }
}

.course
{
  &-section
  {
    padding: 50px 0;
    font-size: 18px;

    h2
    {
      font-family: "Roboto", sans-serif;
      font-size: 40px;
      font-weight: 600;
    }

    &-btn
    {
      padding: 0;
      text-align: center;

      &.mt
      {
        margin-top: -25px;
      }

      &.mb
      {
        margin-bottom: 35px;
      }

      .btn-buy
      {
        background-color: #4caf50;
        color: #fafafa;
        border-radius: 4px;
        border: 1px solid #4caf50;
        transition: 0.3s;
        display: inline-block;
        padding: 14px 32px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;

        &.pulse
        {
          animation: shadow-pulse 2s infinite;
        }
      }
    }

    &-header
    {
      text-align: center;
      margin-bottom: 20px;

      &-label
      {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
      }

      h3
      {
        font-size: 32px;
      }
    }

    .image-box
    {
      padding: 15px 15px 15px 15px;
      background-color: #FFFFFF;
      border-radius: 5px 5px 5px 5px;
      color: #000;
      text-align: center;
      margin-bottom: 25px;

      figure
      {
        width: 30%;
        margin: 0 auto;
      }

      &-content
      {
        h3
        {
          font-size: 28px;
          font-family: "Roboto", sans-serif;
        }

        p
        {
          font-size: 16px;
        }
      }
    }

    .btn-buy
    {
      background-color: #4caf50;
      color: #fafafa;
      border-radius: 4px;
      border: 1px solid #4caf50;
      transition: 0.3s;
      display: inline-block;
      padding: 14px 32px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .accordion
  {
    &-item
    {
      background-color: #f7f9fa;
    }

    &-collapse
    {
      background: #fff;
    }
  }

  .accordion-button
  {
    background-color: #f7f9fa;
    color: #1c1d1f;

    &::before
    {
      flex-shrink: 0;
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 10px;
      content: "";
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      background-size: 1.25rem;
      transition: transform .2s ease-in-out;
    }

    &:not(.collapsed)
    {
      background-color: #f7f9fa;
      color: #1c1d1f;

      &::before
      {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(-180deg);
      }
    }

    &::after
    {
      display: none;
    }

    strong
    {
      flex: 1;
    }

    .time
    {
      margin-left: 2.4rem;
      font-weight: 400;
      line-height: 1.4;
      font-size: 13px;
    }
  }

  .lessons
  {
    &-item
    {
      &-details
      {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: auto;
        text-align: left;
        letter-spacing: normal;
        white-space: normal;
        padding: 8px 0;

        &-content
        {
          display: flex;
          margin-left: 15px;
          width: 100%;
          justify-content: space-between;

          &-time
          {
            color: #6a6f73;
            margin-left: 3.2rem;
          }
        }
      }
    }
  }
}

.ribbon
{
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 10;

  span
  {
    position: absolute;
    display: block;
    width: 225px;
    padding: 8px 0;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
  }

  &-top-left
  {
    top: -10px;
    left: -10px;

    span
    {
      right: -8px;
      top: 30px;
      transform: rotate(-45deg);
    }
  }

  &::after,
  &::before
  {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid;
  }
}

.item-card7
{
  &-img
  {
    position: relative;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
    height: 100%;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    padding-top: 1.5rem !important;

    h4
    {
      margin: 2px;
      color: #fff;
      padding: 5px;
      border-radius: 3px;
      float: right;
      font-size: 20px;
      font-family: 'Roboto Slab', serif;
      font-weight: 700;
    }
  }

  &-imgs
  {
    position: relative;
    overflow: hidden;
    border-radius: 7px;

    &:before
    {
      content: '';
      display: block;
      position: absolute;
      background: transparent;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      z-index: 1;
    }

    &:hover img
    {
      transform: scale(1.1);
    }

    a
    {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    img
    {
      transform: scale(1);
      transition: .3s ease-in-out;
    }
  }

  &-overlaytext
  {
    position: relative;

    &.new
    {
      h4
      {
        background: red;
        color: #fff;
        font-size: 28px;
      }
    }

    &.old
    {
      h4
      {
        bottom: 52px;
        right: 20px;

        &::after
        {
          content: "";
          width: 100%;
          height: 3px;
          background: red;
          position: absolute;
          top: 16px;
          left: 0;
          transform: rotate(-7deg);
        }
      }
    }

    h4
    {
      background: #000;
      position: absolute;
      bottom: 12px;
      right: 12px;
      color: #fff;
      z-index: 2;
      padding: 5px;
      border-radius: 3px;
    }
  }

  &-desc
  {
    color: #5c5776;
  }
}
