:root
{
  --handle-size: 3rem;
  --img-gap: .25rem;
}

body,
html
{
  padding: 0;
  margin: 0;
}

body
{
  font-family: 'Roboto', sans-serif;
  background: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6
{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 15px;
}

ul
{
  list-style-type: none;
  padding: 0;
  margin: 0;

  &.bullet
  {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }

  &.img-list
  {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li
    {
      margin: 0 1em 1em 0;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      position: relative;
      width: 28%;
    }
  }
}

a
{
  text-decoration: none;
  color: $colorPrimary;
}

blockquote
{
  font-size: 16px;
  font-style: italic;
  line-height: 2;
  position: relative;
  margin: 0 0 20px;
  padding: 25px 25px 25px 70px;
  color: #888;
  border-left: 3px solid #ff5b24;
  background: #f7f7f7;

  &:before
  {
    font-family: 'Newsreader';
    font-size: 62px;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    position: absolute;
    top: 15px;
    left: 25px;
    display: block;
    content: '“';
    color: #ff5b24;
  }

  & p:last-child,
  & ul:last-child,
  & ol:last-child
  {
    margin-bottom: 0;
  }
}

.highlight
{
  color: #f8f8f2;
  padding: 2px 4px;
  margin-bottom: 15px;
  background-color: #272822;

  pre
  {
    margin-bottom: 0;
    font-size: 87.5%;
  }

  code
  {
    background-color: transparent;
    padding: 0;
  }

  .hll { background-color: #272822; }
  .c { color: #75715e } /* Comment */
  .err { color: #960050; background-color: #1e0010 } /* Error */
  .k { color: #66d9ef } /* Keyword */
  .l { color: #ae81ff } /* Literal */
  .n { color: #f8f8f2 } /* Name */
  .o { color: #f92672 } /* Operator */
  .p { color: #f8f8f2 } /* Punctuation */
  .cm { color: #75715e } /* Comment.Multiline */
  .cp { color: #75715e } /* Comment.Preproc */
  .c1 { color: #75715e } /* Comment.Single */
  .cs { color: #75715e } /* Comment.Special */
  .ge { font-style: italic } /* Generic.Emph */
  .gs { font-weight: bold } /* Generic.Strong */
  .kc { color: #66d9ef } /* Keyword.Constant */
  .kd { color: #66d9ef } /* Keyword.Declaration */
  .kn { color: #f92672 } /* Keyword.Namespace */
  .kp { color: #66d9ef } /* Keyword.Pseudo */
  .kr { color: #66d9ef } /* Keyword.Reserved */
  .kt { color: #66d9ef } /* Keyword.Type */
  .ld { color: #e6db74 } /* Literal.Date */
  .m { color: #ae81ff } /* Literal.Number */
  .s { color: #e6db74 } /* Literal.String */
  .na { color: #a6e22e } /* Name.Attribute */
  .nb { color: #f8f8f2 } /* Name.Builtin */
  .nc { color: #a6e22e } /* Name.Class */
  .no { color: #66d9ef } /* Name.Constant */
  .nd { color: #a6e22e } /* Name.Decorator */
  .ni { color: #f8f8f2 } /* Name.Entity */
  .ne { color: #a6e22e } /* Name.Exception */
  .nf { color: #a6e22e } /* Name.Function */
  .nl { color: #f8f8f2 } /* Name.Label */
  .nn { color: #f8f8f2 } /* Name.Namespace */
  .nx { color: #a6e22e } /* Name.Other */
  .py { color: #f8f8f2 } /* Name.Property */
  .nt { color: #f92672 } /* Name.Tag */
  .nv { color: #f8f8f2 } /* Name.Variable */
  .ow { color: #f92672 } /* Operator.Word */
  .w { color: #f8f8f2 } /* Text.Whitespace */
  .mf { color: #ae81ff } /* Literal.Number.Float */
  .mh { color: #ae81ff } /* Literal.Number.Hex */
  .mi { color: #ae81ff } /* Literal.Number.Integer */
  .mo { color: #ae81ff } /* Literal.Number.Oct */
  .sb { color: #e6db74 } /* Literal.String.Backtick */
  .sc { color: #e6db74 } /* Literal.String.Char */
  .sd { color: #e6db74 } /* Literal.String.Doc */
  .s2 { color: #e6db74 } /* Literal.String.Double */
  .se { color: #ae81ff } /* Literal.String.Escape */
  .sh { color: #e6db74 } /* Literal.String.Heredoc */
  .si { color: #e6db74 } /* Literal.String.Interpol */
  .sx { color: #e6db74 } /* Literal.String.Other */
  .sr { color: #e6db74 } /* Literal.String.Regex */
  .s1 { color: #e6db74 } /* Literal.String.Single */
  .ss { color: #e6db74 } /* Literal.String.Symbol */
  .bp { color: #f8f8f2 } /* Name.Builtin.Pseudo */
  .vc { color: #f8f8f2 } /* Name.Variable.Class */
  .vg { color: #f8f8f2 } /* Name.Variable.Global */
  .vi { color: #f8f8f2 } /* Name.Variable.Instance */
  .il { color: #ae81ff } /* Literal.Number.Integer.Long */

  .gh { } /* Generic Heading & Diff Header */
  .gu { color: #75715e; } /* Generic.Subheading & Diff Unified/Comment? */
  .gd { color: #f92672; } /* Generic.Deleted & Diff Deleted */
  .gi { color: #a6e22e; } /* Generic.Inserted & Diff Inserted */
}

table
{
  &.table-simple
  {
    width: 100%;
    margin-bottom: 15px;
    border-spacing: 0;
    border-collapse: collapse;

    th
    {
      border-color: transparent;
    }

    tbody tr:nth-child(odd)
    {
      background-color: #f0f0f0;
    }
  }

  &.table
  {
    width: 100%;
    margin-bottom: 15px;
    border-spacing: 0;
    border-collapse: collapse;

    th
    {
      border-color: transparent;
    }

    tbody tr:first-child
    {
      background-color: #f0f0f0;
    }
  }
}

code
{
  background-color: #f9f2f4;
  padding: 2px 4px;
}

.container
{
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
      width:750px
  }
  @media (min-width: 992px) {
      width:970px
  }

  @media (min-width: 1200px) {
      width:1170px
  }

  img
  {
    max-width: 100%;
    height: auto;
  }
}

.clearfix
{
  &:before,
  &:after
  {
    display: table;
    content: " ";
  }

  &:after
  {
    clear: both;
  }
}

.page-header-image
{
  overflow: hidden;
}

.continue-reading
{
  span
  {
    font-size: 18px;
    line-height: 23px;
    color: #1A1A1A;
    padding-right: 10px;
    transition: .3s;
  }

  &:hover svg
  {
    transform: translateX(5px);
  }

  svg {
    color: #ff5b24;
    width: 25px;
    height: 25px;
    transition: 150ms;
  }
}

.divider
{
  background-color: #ff5b24;
  height: 3px;
  width: 46px;
  content: ' ';
  display: block;
  text-align: center;
  margin: 0 auto;
}

.breadcrumb
{
  margin-top: -30px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 0;

  a
  {
    color: #242424;

    &:hover
    {
      color: #ff5b24;
    }
  }
}

.bg-gray
{
  background: var(--color-gray);
}

.bg-orange
{
  background-color: var(--color-primary);
  color: #fff;
}

.text-orange
{
  color: var(--color-primary);
}

.mb-40 {margin-bottom: 40px}
.-mt-50 {margin-top: -50px}

.pt-20 {padding-top: 20px;}