.footer
{
  background: #252525;
  color: #fff;
  padding: 70px 0;

  .copyright
  {
    color: #707070;
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
  }

  .logo
  {
    position: relative;
    padding-left: 72px;
    margin-bottom: 30px;

    &:before
    {
      background-image: url(/assets/images/site/4t_logo_white.png);
      background-size: cover;
      display: block;
      content: '';
      height: 115px;
      width: 115px;
      position: absolute;
      left: -25px;
      top: -28px;
    }

    h1,
    h2
    {
      font-family: 'Newsreader', serif;
      margin-bottom: 0;
      letter-spacing: 1px;
      text-transform: uppercase;
      line-height: 1em;
      font-weight: 400;
      font-size: 25px;
      color: #fff;

      a
      {
        display: block;
        color: #fff;

        span
        {
          display: block;
        }
      }
    }
  }

  .social-media-list
  {
    li
    {
      display: inline-block;

      a
      {
        display: block;
        background: #ff5b24;
        color: #fff;
        border-radius: 50px;
        font-size: 13px;
        height: 35px;
        line-height: 35px;
        margin-right: 4px;
        width: 35px;
        text-align: center;
      }
    }
  }
}