.cooks
{
  .justify-content-center {
    justify-content: center !important;
  }
  .flex-column {
    flex-direction: column !important;
  }
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  .d-flex {
    display: flex !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  .justify-content-between {
    justify-content: space-between !important;
  }
  .justify-content-flex-end {
    justify-content: flex-end !important;
  }

  .post-card:nth-child(2n+1)
  {
    flex-direction: row-reverse;
  }

  .post-counter
  {
    counter-increment: count;

    .post-content
    {
      position: relative;
      width: 50%;
      padding-left: 156px;
      padding-top: 166px;
      justify-content: flex-start !important;

      &::before
      {
        content: counter(count);
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--font-heading);
        font-weight: bold;
        font-size: 250px;
        line-height: 331px;
        color: #bec0c5;
        z-index: -1;
      }

      .title
      {
        font-size: 36px;
        line-height: 48px;
        padding-bottom: 20px;

        a
        {
          color: #000;
        }
      }

      .post-card-excerpt p
      {
        color: var(--color-text);
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 30px;
      }
    }

    &:nth-child(-n+9) .post-content::before {
      content: "0" counter(count);
    }
  }

  .post-card
  {
    margin-top: 100px;
    margin-bottom: 100px;
    justify-content: space-between;

    &-footer
    {
      flex-wrap: wrap;
      align-items: center;

      a
      {
        color: #4A4D56;
      }
    }

    .post-feature-image
    {
      width: calc(50% - 50px);
      position: relative;
      max-width: 620px;
      display: flex;
      justify-content: flex-end;
      z-index: 1;

      a
      {
        display: block;
        position: relative;
        padding-right: 40px;
        padding-bottom: 40px;

        &::before {
          position: absolute;
          content: "";
          right: 0;
          bottom: 0;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          background: #ebebeb;
          z-index: -1;
        }
      }

      img
      {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 300ms;
      }
    }

    .post-content
    {
      .tags a
      {
        color: #ff5b24;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 2px;
        font-family: "Gilroy";
        text-transform: uppercase;
        padding-left: 94px;
        margin-bottom: 34px;
        display: block;
        position: relative;

        &::before
        {
          position: absolute;
          content: "";
          height: 2px;
          width: 72px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background: #ff5b24;
        }
      }
    }
  }
}