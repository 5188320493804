.author
{
  margin-bottom: 50px;

  &-photo
  {
    max-width: 200px;
  }

  &-name
  {
    color: #292929;
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
  }

  &-description
  {
    color: #757575;
    font-size: 14px;
  }
}