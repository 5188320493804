.topic
{
  h2
  {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 42px;
    text-align: center;
    margin-bottom: 60px;
  }

  a
  {
    color: #343a40;
  }

  &-header
  {
    margin-bottom: 50px;

    h2
    {
      margin-bottom: 4px;
    }

    h3
    {
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      text-align: center;
      font-size: 20px;
    }
  }

  &-items
  {
    .col
    {
      max-width: 200px;
    }
  }

  &-item
  {
    margin-bottom: 60px;

    &-image
    {
      width: 150px;
      height: 150px;
      overflow: hidden;
      border-radius: 50%;
      //border: 15px solid #fbf2f2;
      background: #fff;
      margin: 0 auto 20px;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);

      &.screenflow
      {
        box-shadow: none;
        background: no-repeat;
      }

      &.jekyll
      {
        img
        {
          margin-top: 50px;
          margin-left: 10px;
          max-width: 85%;
        }
      }

      &.git
      {
        img
        {
          max-width: 137%;
          margin: -32px;
        }
      }

      &.vagrant,
      &.phpstorm,
      &.database
      {
        padding: 25px;
      }

      &.movies,
      &.elasticsearch,
      &.redis
      {
        background: none;
        box-shadow: none;
      }

      img
      {
        max-width: 100%;
        height: auto;
      }
    }

    footer
    {
      text-align: center;

      .topic-name
      {
        margin-bottom: 5px;
        font-size: 20px;
        font-family: 'Roboto Slab', serif;
      }

      p
      {
        margin-bottom: 0;
        font-size: 16px;
        font-family: 'Roboto', serif;
      }
    }
  }
}