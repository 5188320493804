.section
{
  overflow: hidden;

  &-home
  {
    background: #fff;
    padding: 0 0 50px 0;

    h1
    {
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
    }

    h2, .h2
    {
      font-family: 'Montserrat', sans-serif;
      font-size: 21px;
      font-weight: 300;
      margin-bottom: 50px;
      line-height: 1.4em;

      b
      {
        font-weight: 700;
      }
    }

    .continue-reading
    {
      color: #151515;
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      text-decoration: none;
      background-image: linear-gradient(transparent calc(100% - 3px), #212121 3px);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transition: background-size 0.6s;
      padding-bottom: 5px;

      span
      {
        padding-right: 0;
        font-size: 22px;
      }
    }
  }

  &-learning
  {
    background: #fff;
    padding-top: 50px;
  }

  &-last-posts
  {

    .header
    {
      display: flex;
      justify-content: space-between;
      padding: .5rem calc(var(--img-gap) * 2 + var(--handle-size));
      align-items: center;

      .title
      {
        font-family: 'Roboto Slab', serif;
        font-size: 2rem;
        margin: 0;
      }

      .slider-progress-bar
      {
        display: flex;
        gap: .25rem;

        .slider-progress-item
        {
          flex: 0 0 1.5rem;
          min-width: 1.5rem;
          height: .5rem;
          background-color: #9e9e9e;

          &.active
          {
            background-color: #ff5b25;
          }
        }
      }
    }

    &-container
    {
      display: flex;
      justify-content: center;
      overflow: hidden;

      &:hover .handle,
      &:focus .handle
      {
        background-color: rgba(0, 0, 0, .5);

        .text
        {
          transform: scale(1.2);
        }
      }

      .slider
      {
        --items-per-screen: 5;
        --slider-index: 0;
        display: flex;
        flex-grow: 1;
        margin: 0 var(--img-gap);
        transform: translateX(calc(var(--slider-index) * -100%));
        transition: transform 250ms ease-in-out;

        &-item
        {
          flex: 0 0 calc(100% / var(--items-per-screen));
          max-width: calc(100% / var(--items-per-screen));
          padding: var(--img-gap);
        }
      }

      .handle
      {
        border: none;
        border-radius: 1rem;
        flex-grow: 0;
        flex-shrink: 0;
        background-color: rgba(255, 255, 255, .25);
        z-index: 10;
        margin: var(--img-gap) 0;
        width: var(--handle-size);
        cursor: pointer;
        font-size: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        line-height: 0;
        transition: background-color 150ms ease-in-out;

        .text
        {
          transition: transform 150ms ease-in-out;
        }
      }

      .left-handle
      {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .right-handle
      {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    h2
    {
      font-family: 'Roboto Slab', serif;
    }

    .hr
    {
      position: relative;

      hr
      {
        color: #9c9c9cc3;
      }

      .arrow
      {
        background: #fff;
        width: 10px;
        height: 10px;
        border-right: 1px solid #c3c3c3;
        border-bottom: 1px solid #c3c3c3;
        display: block;
        position: absolute;
        left: 50%;
        margin-left: -5px;
      }

      .arrow-down
      {
        top: -4px;
        transform: rotate(45deg);
      }

      .arrow-up
      {
        top: -5px;
        transform: rotate(230deg);
      }
    }

    .post
    {
      &-image-wrapper
      {
        border-radius: 5px;
        margin-bottom: 30px;
        overflow: hidden;
      }

      &-categories
      {
        position: relative;
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: bold;
        text-transform: lowercase;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        a
        {
          display: flex;
          align-items: center;
          font-weight: normal;
          text-decoration: none;
          padding: 1px 15px 1px 10px;
          background-color: #fff;
          border: 1px solid #e0dede;
          color: #0f0e0e;
          margin-bottom: 5px;
          margin-right: 10px;
          word-break: break-word;
          min-height: 28px;
          line-height: 15px;
          border-radius: 30px;
        }

        .cat-dot
        {
          display: inline-block;
          width: 14px;
          height: 14px;
          position: relative;
          border-radius: 100%;
          margin-right: 10px;
          background-color: #994db1;
          flex-shrink: 0;

          &.hardware
          {
            background-color: #286f90;
          }

          &.books
          {
            background-color: #b1be7f;
          }

          &.regex
          {
            background-color: #f9df2e;
          }

          &.rabbitmq
          {
            background-color: #ff5b25;
          }
        }
      }

      &-title
      {
        font-size: 1rem;
        display: block;
        color: #0f0e0e;
        margin-top: 0;
        margin-bottom: 5px;
        word-wrap: break-word;

        a
        {
          color: #0f0e0e;
        }
      }

      &-date
      {
        display: inline-block;
        color: #9F9F9F;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      &-info-dot
      {
        display: inline-block;
        margin-left: 7px;
        margin-right: 7px;
        border-radius: 5px;
        width: 6px;
        height: 6px;
        border: 1px solid #909090;
        top: -1px;
        position: relative;
      }

      &-read-time
      {
        display: inline-block;
        color: #9F9F9F;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }

  &-books
  {
    background: #fff;
    padding: 60px;

    h2
    {
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      font-size: 42px;
      text-align: center;
      margin-bottom: 30px;
    }

    .nav
    {
      &-item
      {
        margin: 0 32px;
      }

      &-link
      {
        color: #9e9e9e;
        border-radius: 0;
        font-family: 'Montserrat', sans-serif;

        &.active
        {
          color: #161619;
          font-weight: 500;
          border-bottom: 1px solid #161619;
          background: transparent;
        }
      }
    }

    .no-gutters
    {
      margin-right: 0;
      margin-left: 0;

      & > .col
      {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .book
    {
      transition: all .2s ease-in-out;
      position: relative;
      height: 370px;

      &-wrapper
      {
        box-shadow:
                1px 0 0 0 #eae8e4,
                0 1px 0 0 #eae8e4,
                1px 1px 0 0 #eae8e4,   /* Just to fix the corner */
                1px 0 0 0 #eae8e4 inset,
                0 1px 0 0 #eae8e4 inset;

        &:hover
        {
          box-shadow: 0 0 40px 0 rgba(22,22,25,.1);

          .links
          {
            opacity: 1;
          }

          .info
          {
            transform: translateY(-44px);
          }
        }
      }

      .thumbnail
      {
        img
        {
          margin-left: auto!important;
          margin-right: auto!important;
          display: block!important;
          max-width: 100%;
          height: auto;
        }
      }

      .info
      {
        padding-top: 16px;
        position: relative;
        z-index: 1;
        transition: all .2s ease-in-out;
        background: #fff;

        .format
        {
          color: #f75454;
          text-transform: uppercase;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          margin-bottom: 4px;
        }

        .title
        {
          a
          {
            color: #161619;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }

        .author
        {
          min-height: 48px;
        }
      }

      .links
      {
        transition: all .2s ease-in-out;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        font-size: 18px;
        color: #161619;
        align-items: center;
        display: flex;

        a:first-child
        {
          margin-right: auto;
        }
      }
    }
  }

  &-newsletter
  {
    background: #f8f8f8;
    padding: 60px;

    h2
    {
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      font-size: 42px;
      text-align: center;
      margin-bottom: 4px;
    }

    h3
    {
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      text-align: center;
      font-size: 20px;
      line-height: 1.4em;
    }

    .divider
    {
      margin-bottom: 30px;
    }
  }
}