#work-time {
  height: 500px;
}

.highcharts-figure, .highcharts-data-table table {
  min-width: 320px;
  max-width: 700px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #EBEBEB;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}
.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}
.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
  padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #f1f7ff;
}


.highcharts-figure
{
  position: relative;

  .image
  {
    position: absolute;
    z-index: 1;
    left: 100px;
    max-width: 123px;
    top: 192px;

    @media (min-width: 425px) {
      left: 122px;
      max-width: 132px;
      top: 184px;
    }

    @media (min-width: 768px)
    {
      left: 111px;
      max-width: 480px;
      top: 38px;
    }
  }

  .chart
  {
    position: relative;
    z-index: 2;
  }

  .label
  {
    font-family: 'Roboto Slab', serif;
  }
}
