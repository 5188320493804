.header
{
  position: relative;
  background-color: #fff;
  height: var(--nav-height);

  &-wrapper
  {
    position: sticky;
    top: 0;
    z-index: 11;
    border-bottom: 1px solid #eee;
    margin-bottom: 50px;
    box-shadow: 2px 3px 4.7px 0.3px rgba(0, 0, 0, 0.1);

    .btn-buy
    {
      background-color: #d32f2f;
      color: #fafafa;
      border-radius: 4px;
      border: 1px solid #d32f2f;
      transition: 0.3s;
      display: block;
      padding: 14px 32px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      animation: shadow-pulse 2s infinite;
    }

    @keyframes shadow-pulse {
      0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
      }
      100% {
        box-shadow: 0 0 0 12px rgba(0, 0, 0, 0);
      }
    }
  }

  &-inner
  {
    position: relative;
  }

  .logo
  {
    margin-top: 18px;
    margin-bottom: 17px;
    position: relative;
    padding-left: 72px;

    &:before
    {
      background-image: url(/assets/images/logo_4t.png);
      background-size: cover;
      display: block;
      content: '';
      height: 115px;
      width: 115px;
      position: absolute;
      left: -25px;
      top: -28px;
    }

    h1,
    h2
    {
      font-family: 'Newsreader', serif;
      margin-bottom: 0;
      letter-spacing: 1px;
      text-transform: uppercase;
      line-height: 1em;
      font-weight: 400;
      font-size: 25px;

      a
      {
        display: block;
        color: #ff5b24;

        span
        {
          color: #000;
          display: block;
        }
      }
    }
  }

  nav
  {
    padding: 0;

    ul
    {
      margin: 0;
      padding: 0;
      list-style: none;

      li
      {
        display: block;
        float: left;

        .nav-link
        {
          font-size: 12px;
          font-weight: 700;
          height: 80px;
          line-height: 78px;
          display: block;
          padding: 0 15px!important;
          text-decoration: none;
          text-transform: uppercase;
          color: #666;
          border-top: 2px solid transparent;
        }

        ul
        {
          display: none;
        }
      }
    }

    .navbar-collapse
    {
      &.show,
      &.collapsing
      {
        position: fixed;
        top: 86px;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        border: 1px solid #222;
      }

      .nav-link
      {
        height: 50px;
        line-height: 50px;
      }
    }
  }
}