#clockdiv
{
  font-family: sans-serif;
  color: #fff;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
  margin-top: -50px;
  margin-bottom: 50px;

  div
  {
    padding: 10px;
    border-radius: 3px;
    background: #ff5b25;
    display: inline-block;

    span
    {
      padding: 15px;
      border-radius: 3px;
      background: #212121;
      display: inline-block;
    }
  }

  .smalltext
  {
    padding-top: 5px;
    font-size: 16px;
  }
}
