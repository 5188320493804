.day
{
  font-size: 21px;
  font-weight: 300;
  padding-bottom: 70px;

  *[id]
  {
    scroll-margin-top: var(--nav-height-scroll);
  }

  .tldr
  {
    margin-bottom: 32px;
  }

  h1
  {
    margin-top: 25px;
  }

  h2
  {
    font-size: 30px;
    font-weight: 500;
    margin-top: 55px;
  }

  h3
  {
    font-size: 26px;
    font-weight: 500;
    margin-top: 55px;
  }

  &-list
  {
    margin-top: 50px;

    ul
    {
      list-style: none;
      padding: 0;
      margin: 0;

      li
      {
        counter-increment: popcounter -1;
        list-style: none;
        padding: 0 10px 80px 10px;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;

        &:first-child::before
        {

        }

        &::before
        {
          content: counter(popcounter);
          padding: 11px 18px;
          font-size: 18px;
          font-weight: 700;
          top: 165px;
          line-height: 17px;
          color: #ffffff;
          background: #ff5b24;
          border-radius: 2px;
          left: 26px;
          position: absolute;
          z-index: 1;
        }
      }
    }
  }

  &-image
  {
    margin-bottom: 15px;
  }

  &-thumbnail
  {
    margin: 0 20px 0 0;
    overflow: hidden;
    float: left;

    a
    {
      display: block;
      width: 400px;
      height: 187px;
      overflow: hidden;
    }

    img
    {
      padding: 0;
      display: block;
      height: auto;
      max-width: 100%;
      object-fit: cover;
    }
  }

  &-content
  {
    &-title
    {
      padding: 6px 10px 10px 19px;

      &:hover
      {
        a
        {
          background-size: 100% 100%;
        }
      }

      a
      {
        color: #151515;
        font-size: 24px;
        font-weight: 600;
        line-height: 34px;
        text-decoration: none;
        background-image: linear-gradient(transparent calc(100% - 3px),#212121 3px);
        background-repeat: no-repeat;
        background-size: 0 100%;
        transition: background-size 0.6s;
      }
    }

    &-description
    {
      font-weight: 300;
      color: gray;
      font-size: 16px;
      line-height: 24px;
    }
  }
}